<template>
  <v-row no-gutters>
    <v-col class="text-center">
      <v-tooltip bottom :open-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            @click="mostrarDialogMapaCoordenada = true"
            v-on="on"
          >
            <v-icon>
              mdi-target
            </v-icon>
          </v-btn>
        </template>
        <span>Ponto no mapa</span>
      </v-tooltip>

      <v-tooltip
        :open-delay="200"
        v-if="$temPermissao('leituras-perfil-veiculo')"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            @click="$emit('perfil-veiculo')"
            v-on="on"
          >
            <v-icon>
              mdi-chart-bar
            </v-icon>
          </v-btn>
        </template>
        <span>Perfil do veículo</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            @click="$emit('editar-placa')"
            v-on="on"
          >
            <v-icon>
              mdi-pencil-outline
            </v-icon>
          </v-btn>
        </template>
        <span>Editar placa</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            @click="$emit('criar-ocorrencia')"
            v-on="on"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <span>Criar ocorrência</span>
      </v-tooltip>

      <v-tooltip
        v-if="$temPermissao('leituras-exportar-imagem')"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            :loading="carregandoDownloadImagens"
            v-on="on"
            @click="downloadImagens()"
          >
            <v-icon>
              mdi-file-image
            </v-icon>
          </v-btn>
        </template>
        <span>Download imagem</span>
      </v-tooltip>

      <v-tooltip
        v-if="$temPermissao('leituras-exportar-pdf')"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            :loading="carregandoDownloadPdf"
            v-on="on"
            @click="downloadPdf()"
          >
            <v-icon>
              mdi-file-pdf-box
            </v-icon>
          </v-btn>
        </template>
        <span>Download PDF</span>
      </v-tooltip>

      <v-tooltip
        v-if="$temPermissao('leituras-desativacao')"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            :loading="carregandoDesativarLeitura"
            v-on="on"
            @click="desativar()"
          >
            <v-icon>
              mdi-car-off
            </v-icon>
          </v-btn>
        </template>
        <span>Desativar leitura</span>
      </v-tooltip>
    </v-col>

    <dialog-mapa-coordenada
      :mostrar="mostrarDialogMapaCoordenada"
      :latitude="Number.parseFloat(leitura.latitude)"
      :longitude="Number.parseFloat(leitura.longitude)"
      @fechar="mostrarDialogMapaCoordenada = false"
    />
  </v-row>
</template>

<script>
  import apiLeituras from '@/api/leituras'

  export default {

    components: {
      DialogMapaCoordenada: () => import('@/components/dialog/DialogMapaCoordenada'),
    },

    props: {
      leitura: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        carregandoDownloadPdf: false,
        carregandoDownloadImagens: false,
        carregandoDesativarLeitura: false,
        mostrarDialogMapaCoordenada: false,
      }
    },

    methods: {
      async downloadPdf () {
        try {
          this.carregandoDownloadPdf = true

          const resposta = await apiLeituras.exportar(this.leitura.id)

          const linkSource = `data:application/pdf;base64,${resposta.data.pdf}`
          const downloadLink = document.createElement('a')
          const fileName = `leitura_${this.leitura.id}`

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: 'Erro ao baixar PDF' })
        } finally {
          this.carregandoDownloadPdf = false
        }
      },

      async downloadImagens () {
        try {
          if (this.leitura.imagens && this.leitura.imagens.length <= 0) {
            return
          }

          this.carregandoDownloadImagens = true

          const imagem = this.leitura.imagens[0]
          const resposta = await apiLeituras.exportarImagem(imagem.id)

          const linkSource = `data:image/png;base64,${resposta.data.imagem}`
          const downloadLink = document.createElement('a')
          const fileName = imagem.caminho_imagem

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: 'Erro ao baixar imagens' })
        } finally {
          this.carregandoDownloadImagens = false
        }
      },

      async desativar () {
        try {
          this.carregandoDesativarLeitura = true

          await apiLeituras.desativar(this.leitura.id)

          this.$snackbar.mostrar({ cor: 'success', mensagem: 'Leitura desativada com sucesso' })
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: 'Erro ao desativar leitura' })
        } finally {
          this.carregandoDesativarLeitura = false
        }
      },
    },

  }
</script>
